const STOCK_TYPES = Object.freeze({
    D48: 'D48',
    E24: 'E24',
    E25: 'E25',
    E48: 'E48',
    S24: 'S24',
    S48: 'S48',
    V48: 'V48',
    V05: 'V05',
    STANDARD: 'STANDARD',
    DER: 'DER',
    NO: 'NO',
});

export default STOCK_TYPES;
